<!-- 17, 17-A -->
<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="fab-transition"
    max-width="1000"
  >
    <v-card>
      <div class="d-flex justify-space-between mb-5" id="modal-header">
        <v-card-title class="py-3">콘텐츠 업로드</v-card-title>
        <div class="mt-3 mr-2">
            <!-- <v-btn text>
            </v-btn> -->
          <v-btn icon text @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <v-card-text
        v-if="!uploaded"
        class="d-flex flex-column align-center my-md-12 py-md-12 my-sm-8 py-sm-8 my-xs-0 py-xs-0 my-12 py-12"
      >
        <div v-if="!uploading" class="text-center">
          <div>
            <v-btn
              icon
              class="grey lighten-2 mb-4"
              style="height: 104px;width: 104px;"
              @click="selectFile"
              ><v-icon x-large class="grey--text text--darken-1"
                >mdi-upload</v-icon
              ></v-btn
            >
          </div>

          <ValidationProvider
            rules="required|size:5000"
            v-slot="{ errors }"
            name="file"
            ref="provider"
          >
            <v-file-input
              @change="uploadVideo"
              accept="image/*, .pdf"
              placeholder="콘텐츠 파일을 드래그 앤 드롭하여 업로드"
              :error-messages="errors"
              ref="fileInput"
              hide-details
            >
          </v-file-input>
            <div class="text-center text-caption mt-1" style="width: 100%;">콘텐츠를 게시하기 전에는 비공개로 설정됩니다</div>
          </ValidationProvider>
          <v-btn
            type="submit"
            depressed
            @click="$refs.fileInput.$refs.input.click()"
            class="mt-4"
            rounded
            >파일 선택</v-btn
          >
        </div>

        <v-progress-circular
          v-else
          :rotate="360"
          :size="100"
          :width="15"
          :value="value"
          color="teal"
        >
          {{ value }}
        </v-progress-circular>
      </v-card-text>
      <v-card-text v-else>
        <template>
          <h2>원피스 1280화</h2>
    <v-stepper v-model="currStep" alt-labels flats class="my-5">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="currStep>1" >
          세부정보
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2" :complete="currStep>2" >
          콘텐츠 요소
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" :complete="currStep>3"
          :rules="[() => infringementDetected === false]"
          >
          검토
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="4" >
          공개 상태
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

        </template>
      <template v-if="currStep ===1">

        <h2 class="mb-6">세부정보</h2>
        <v-row>
          <v-col
            order="last"
            order-sm="last"
            order-md="first"
            order-lg="first"
            order-xl="first"
            cols="12"
            sm="12"
            md="8"
            lg="8"
          >
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <form
                @submit.prevent="handleSubmit(submit)"
                @reset.prevent="reset"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Title"
                >
                  <v-text-field
                    v-model="formData.title"
                    :error-messages="errors"
                    label="제목 (필수 항목)"
                    placeholder="제목을 입력해 주세요."
                    persistent-placeholder
                    class="mb-3"
                    filled
                    dense
                    counter="100"
                    max-length="100"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Description"
                >
                  <v-textarea
                    filled
                    auto-grow
                    :error-messages="errors"
                    label="설명"
                    placeholder="감상자에게 콘텐츠에 대해 설명해 주세요."
                    persistent-placeholder
                    rows="5"
                    counter="5000"
                    max-length="5000"
                    v-model="formData.description"
                    row-height="20"
                  ></v-textarea>
                </ValidationProvider>

                
                <p class="mb-1">썸네일</p>
                <p class="text-caption">눈에 띄고 감상자의 관심을 끄는 썸네일을 설정하세요.</p>
                
                
            <input type="file" id="upload-image" hidden ref="uploadImage" multiple />
            <v-btn
              tile
              class="grey lighten-2 mb-4 mr-2 icon-text"
              style="height: 100px;width: 180px;"
              @click="uploadImage"
              ><v-icon large class="grey--text text--darken-1"
                >mdi-file-image-plus-outline</v-icon
              >
              <span class="grey--text text--darken-1"
              >
                파일 업로드
              </span>
              </v-btn
            >
            <v-btn
              @click="generateDialog=true"
              tile
              class="grey lighten-2 mb-4 icon-text"
              style="height: 100px;width: 180px;"
              ><v-icon large class="grey--text text--darken-1"
                >mdi-creation-outline</v-icon
              >
              <span class="grey--text text--darken-1">
                자동 생성됨
              </span>
              </v-btn
            >
            <v-dialog
              v-model="generateDialog"
              max-width="500px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title>
                  자동 생성된 썸네일
                </v-card-title>
                <v-card-text>
                  동영상에서 썸네일로 사용할 이미지를 선택하세요
                  <div class="d-flex">
                    <v-avatar
                      width="100"
                      height="70"
                      class="ma-3"
                      color="grey"
                      tile
                    >
                      <img src="src" alt="alt">
                    </v-avatar>
                    <v-avatar
                      width="100"
                      height="70"
                      class="ma-3"
                      color="grey"
                      tile
                    >
                      <img src="src" alt="alt">
                    </v-avatar>
                    <v-avatar
                      width="100"
                      height="70"
                      class="ma-3"
                      color="grey"
                      tile
                    >
                      <img src="src" alt="alt">
                    </v-avatar>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="generateDialog=false" color="secondary" rounded>취소</v-btn>
                  <v-btn @click="generateDialog=false" color="primary" rounded>완료</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
             <p class="mb-1">장르 목록</p>
                <p class="text-caption">장르를 정해 콘텐츠를 분류해 주세요</p>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="category"
                >
                  <v-select
                    :items="categories"
                    :error-messages="errors"
                    filled
                    placeholder="선택"
                    v-model="formData.category"
                  ></v-select>
                </ValidationProvider>

              </form>
            </ValidationObserver>
          </v-col>
          <v-col
            order-sm="1"
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="text-center"
          >
            <!-- <v-btn text @click="toggleShow">Upload Thumbnails</v-btn> -->
            <!-- <my-upload
              field="img"
              @crop-success="cropSuccess"
              v-model="show"
              :width="400"
              :height="400"
              :params="params"
              :headers="headers"
              img-format="jpg"
              langType="en"
            ></my-upload> -->
            <v-responsive width="330" class="mx-auto">
              <div v-if="!imgDataUrl" class="px-12" id="image-placeholder">
                <v-icon>mdi-image-plus</v-icon>
              </div>
              <v-img
                v-else
                max-width="330"
                height="200"
                :src="imgDataUrl"
              ></v-img>
              <div>
                <v-text-field
                  label="콘텐츠 링크"
                  value="https://grape.be/Ikk43h54o"
                  append-icon="mdi-content-copy"
                  readonly
                ></v-text-field>
                <v-text-field
                  label="파일 이름"
                  value="filename.pdf"
                  append-icon="mdi-monitor-eye"
                  readonly
                  @click:append="$router.push('/reader/comics')"
                ></v-text-field>
                <v-radio-group v-model="viewType" row>
                  <v-radio label="스크롤형" value="scroll">
                    <template v-slot:label>
                      <v-icon class="mr-1"> mdi-mouse-move-vertical</v-icon>
                      스크롤형
                    </template>
                  </v-radio>
                  <v-radio label="페이지형" value="page">
                    <template v-slot:label>
                      <v-icon class="mr-1"> mdi-book-open-variant-outline</v-icon>
                      페이지형
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-responsive>
    
          </v-col>
        </v-row>
        
        <!-- <div class="mt-6 d-flex justify-end" style="width:100%">
                  <v-btn class="primary" depressed rounded @click="currStep=2">다음</v-btn>
                </div> -->

      </template>
      <template v-else-if="currStep===2">
        <h2 class="mb-6">콘텐츠 요소</h2>

        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <div class="d-flex align-center justify-start">
                  <v-icon class="mr-2">mdi-handshake-outline</v-icon>
                  <div class="flex-grow-1" style="width: 100%;">
                    <p class="my-0" >번역 도움 기능</p>
                     <p class="text-caption my-0">전 세계에서 활동중인 실력있는 번역가에게 도움을 요청해 보세요.</p>
                  </div>
                </div>
              </v-card-title>
              <v-card-text>
                <p class="my-0" >미번역 작품 올리기</p>
                <p class="text-caption my-0">번역가분들이 수정할 수 있는  원본 파일을 올려주세요.</p>
                  
            <v-btn
              tile
              class="grey lighten-2 my-4 mr-2 icon-text"
              style="height: 100px;width: 180px;"
              @click="uploadImage"

              ><v-icon large class="grey--text text--darken-1"
              
                >mdi-file-image-plus-outline</v-icon
              >
              <span class="grey--text text--darken-1"
              >
                파일 업로드
              </span>
              </v-btn
            >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>  
      <template v-else-if="currStep===3">
        <h2 class="mb-6">검토</h2>

        <v-row>
          <v-col>
            <v-btn rounded @click="infringementDetected=false">문제 해결</v-btn>

          </v-col>
        </v-row>
      </template>  
      <template v-else-if="currStep===4">
        <h2 class="mb-6">공개상태</h2>

        <v-row>
          <v-col>

          </v-col>
        </v-row>
      </template>  
      
      <div class="mt-6 d-flex justify-end align-center" style="width:100%">
          <div>
            <template v-if="infringementDetecting">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mr-1"

              ></v-progress-circular>
              검사중
            </template>
            <template v-else-if="infringementDetected">
              <v-icon
              class="mr-1"
              >
                mdi-check-circle-outline
              </v-icon>
              검사가 완료되었습니다 저작권 보호 콘텐츠가 발견되었습니다. <a class="text-decoration-none" style=" color:#065fd4">검토로 이동</a>
            </template>
            <template v-else>
              <v-icon
              class="mr-1"
              >
                mdi-check-circle-outline
              </v-icon>
              검사가 완료되었습니다.
            </template>
          </div>
      <v-spacer></v-spacer>
              <v-btn class="secondary" depressed rounded @click="currStep--" v-if="currStep>1" >이전</v-btn>
            <v-btn class="ml-2 primary" depressed rounded @click="currStep++" v-if="currStep<4">다음</v-btn>
            <v-btn class="ml-2 primary" depressed rounded @click="currStep++" v-else>업로드</v-btn>
          </div>

      </v-card-text>
      <v-card-actions v-if="!uploaded">
        <p class="text-center grey--text caption px-12 px-xs-0" style="width: 100%;">
          Grape에 콘텐츠를 제출하면 Grape 서비스 약관 및 커뮤니티 가이드에 동의하게 됩니다. <br>
          불법 저작물 게시시 삭제 조치되고 관련 법에 따라 처벌 받을 수 있습니다 타인의 저작권 또는 개인정보 보호 권리를 침해해서는 안 됩니다. 자세히 알아보기.
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import myUpload from 'vue-image-crop-upload'
export default {
  name: 'UploadModal',
  props: ['openDialog'],
  data: function() {
    return {
      // dialog: this.openDialog ? this.openDialog : false,
      valid: false,
      uploaded: false,
      uploading: false,
      interval: {},
      value: 0,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'Video size should be less than 5 MB!'
      ],
      categories: ['만화', '웹툰','문학','매거진','뉴스'],
      visibilty: ['Public', 'Private'],
      formData: {
        title: '',
        description: '',
        category: '',
        visibilty: ''
      },
      imgDataUrl: 'https://picsum.photos/500/300',
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      generateDialog:false,
      viewType:'scroll',
      currStep:1,
      infringementDetected:false,
      infringementDetecting:false,
    }
  },
  computed: {
    dialog() {
      return this.openDialog
    }
  },
  methods: {
    async uploadVideo(e) {
      const { valid } = await this.$refs.provider.validate(e)

      if (!valid) return
      // TODO: Upload the file
      this.uploading = true
      this.interval = setInterval(() => {
        if (this.value === 100) {
          this.uploaded = true
          clearInterval(this.interval)
        }
        this.value += 10
      }, 100)
      // }
      // }
      this.infringementDetect()
    },
    submit() {
      if (this.$route.name === 'Dashboard')
        return this.$router.push('/studio/videos')
      console.log('submittied')
      this.closeModal()
    },
    closeModal() {
      this.$emit('closeDialog')
    },
    selectFile() {
      this.$refs.fileInput.$refs.input.click()
    },
    toggleShow() {
      this.show = !this.show
    },
    cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------')
      console.log(field)
      this.imgDataUrl = imgDataUrl
    },
    infringementDetect(){
      this.infringementDetecting = true
      setTimeout(() => {
        this.infringementDetecting = false
        this.infringementDetected = true
      }, 5000);
    },
    
    uploadImage(){
      this.$refs.uploadImage.click();
    },
  },
  components: {
    // myUpload
  }
}
</script>

<style lang="scss">
.v-input__prepend-outer{
  display: none;
}
#modal-header {
  border-bottom: 1px solid rgb(238, 232, 232);
}

#image-placeholder {
  padding-top: 8em;
  padding-bottom: 8em;
  border: 2px dashed rgb(209, 209, 209);
}

.icon-text .v-btn__content{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
</style>
