import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        dark: true ,
        themes:{
            light:{
                primary: '#5c2e91',
                // light.secondary = grey darken-3 , default = '#424242'
            },
            dark:{
                primary: '#5c2e91',
                secondary: '#BDBDBD'
                // dark.secondary = grey lighten-1
            },
        },
    },
});
