<!-- 18 -->
<template>
    <v-dialog
      :value="dialog"
      @input="dialog = $event"
      max-width="500px"
      scrollable
    >   
      <v-card>
        <v-card-title>
            알림
            <v-spacer></v-spacer>
            <v-btn fab text small router to="/config/notification" close-on-click><v-icon>mdi-cog</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- <v-divider></v-divider> -->
        <v-container style="max-height: 600px;" class="px-0">
            <v-card
            v-for="item in items"
            :key="item.id"
            flat
            >
                
                <v-list-item three-line class="px-3">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                          :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                <v-list-item-content class="align-self-auto">
                    <p>
                        {{ item.content }}
                    </p>
                </v-list-item-content>
                <v-list-item-avatar 
                    width="70"
                    height="40"
                    color="blue"
                    class="mr-2 align-self-auto"

                tile
                >
                    <v-img :src="require('@/assets/thumbnails/thumbnail_06.jpg')"></v-img>
                </v-list-item-avatar>
                <v-btn text small fab><v-icon>mdi-dots-vertical</v-icon></v-btn>

                </v-list-item>
            </v-card>
            
        </v-container>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          {
            id: 1,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 2,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 3,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 4,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 5,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 6,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 7,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 8,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 9,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
          {
            id: 10,
            title: '제목',
            content: '내용',
            date: '2020-01-01',
          },
        ],
      };
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
  </script>