import Vue from 'vue'
import VueRouter from 'vue-router'
import NavBar from '@/components/NavBar.vue'
import StudioNavBar from '@/components/StudioNavBar.vue'
import MyChannelNavBar from '@/components/MyChannelNavBar.vue'
import ConfigNavBar from '@/components/ConfigNavBar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'Test',
    components: {
      ConfigNavBar,
      // NavBar,
      default: () => import(/* webpackChunkName: "about" */ '../views/Test.vue')
    }
  },
  {
    path: '/ietest',
    name: 'IETest',
    components: {
      // MyChannelNavBar,
      NavBar,
      default: () => import(/* webpackChunkName: "about" */ '../views/IETest.vue')
    }
  },
  {
    path: '/pdftest',
    name: 'PdfTest',
    components: {
      // MyChannelNavBar,
      NavBar,
      default: () => import(/* webpackChunkName: "about" */ '../views/PdfTest.vue')
    }
  },
  {
    path: '/epubtest',
    name: 'EpubTest',
    components: {
      // MyChannelNavBar,
      NavBar,
      default: () => import(/* webpackChunkName: "about" */ '../views/EpubTest.vue')
    }
  },
  {
    path: '/',
    name: 'Home',
    components: {
      NavBar,
      default: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () =>
      import(/* webpackChunkName: "signin" */ '../views/Auth/SignIn.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () =>
      import(/* webpackChunkName: "signup" */ '../views/Auth/SignUp.vue')
  },
  {
    path: '/trending',
    name: 'Trending',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "trending" */ '../views/Trending.vue')
    }
  },
  {
    path: '/studio',
    components: {
      StudioNavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Studio/Index.vue')
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Dashboard.vue'
          )
      },
      {
        path: 'contents',
        name: 'Contents',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Studio/Contents.vue')
      },
      {
        path: 'analitics',
        name: 'Analitics',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Analitics.vue'
          )
      },
      {
        path: 'comments',
        name: 'Comments',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Comments.vue'
          )
      },
      {
        path: 'translation',
        name: 'Translation',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Translation.vue'
          )
      },
      {
        path: 'translation/confirm',
        name: 'TranslationConfirm',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/TranslationConfirm.vue'
          )
      },
      {
        path: 'translation/edit',
        name: 'TranslationEdit',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/TranslationEdit.vue'
          )
      },
      {
        path: 'translation/submit',
        name: 'TranslationSubmit',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/TranslationSubmit.vue'
          )
      },
      {
        path: 'copyright',
        name: 'Copyright',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Copyright.vue'
          )
      },
      {
        path: 'monetization',
        name: 'Monetization',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Monetization.vue'
          )
      },
      {
        path: 'customization',
        name: 'Customization',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Customization.vue'
          )
      },
      {
        path: 'config',
        name: 'Config',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Config.vue'
          )
      },
      {
        path: 'feedback',
        name: 'SendFeedback',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/SendFeedback.vue'
          )
      },
      {
        path: 'videos',
        name: 'Video',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Studio/Video.vue')
      },
      {
        path: 'details/:id',
        name: 'Detail',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Studio/Details.vue')
      }
    ]
  },
  {
    path: '/mychannel',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Channel/Index.vue')
    },
    children: [
      {
        path: '/',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Channel/EmptyChannel.vue'
          )
      },
      {
        path: 'main',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Channel/MyChannelMain.vue'
          )
      }
    ]
  },
  {
    path: '/channels/:id',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Channel/Index.vue')
    },
    children: [
      {
        path: '/',
        name: 'ChannelHome',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Channel/Home.vue'
          )
      }
    ]
  },
  {
    path: '/watch/:id',
    name: 'Watch',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Watch.vue')
    }
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Channel/Subscriptions.vue')
    }
  },
  {
    path: '/config',
    components: {
      ConfigNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Config/Index.vue')
    },
    children:[
      {
        path: '/',
        name: 'AccountConfig',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Config/Account.vue')
      },
      {
        path: 'notification',
        name: 'NotificationConfig',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Config/Notification.vue')
      },
      {
        path: 'appearance',
        name: 'AppearanceConfig',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Config/Appearance.vue')
      },
      {
        path: 'advanced',
        name: 'AdvancedConfig',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Config/Advanced.vue')
      }
    ]
  },
  {
    path: '/reports',
    name: 'Reports',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Channel/Reports.vue')
    }
  },
  {
    path: '/subscriptionsmanage',
    name: 'SubscriptionsManage',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Channel/SubscriptionsManage.vue')
    }
  },
  {
    path: '/history',
    name: 'History',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/History.vue')
    }
  },
  {
    path: '/playlist',
    name: 'Playlist',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Playlist.vue')
    }
  },
  {
    path: '/watchlater',
    name: 'WatchLater',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/WatchLater.vue')
    }
  },
  {
    path: '/likes',
    name: 'Likes',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Likes.vue')
    }
  },
  {
    path: '/search',
    name: 'Search',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Search.vue')
    }
  },
  {
    path: '/reader',
    components: {
      MyChannelNavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Reader/Index.vue')
    },
    children: [
      {
        path: 'comics',
        name: 'Comics',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Reader/Comics.vue'
          )
      },
      {
        path: 'news',
        name: 'News',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Reader/News.vue'
          )
      },
      {
        path: 'novel',
        name: 'Novel',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Reader/Novel.vue'
          )
      },
      {
        path: 'magazine',
        name: 'Magazine',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Reader/Magazine.vue'
          )
      },
      // {
      //   path: 'videos',
      //   name: 'Video',
      //   component: () =>
      //     import(/* webpackChunkName: "video" */ '../views/Reader/Video.vue')
      // },
      // {
      //   path: 'details/:id',
      //   name: 'Detail',
      //   component: () =>
      //     import(/* webpackChunkName: "video" */ '../views/Reader/Details.vue')
      // }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
