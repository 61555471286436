<!-- 1~~ -->
<template>
  <nav id="navbar">
    <!-- <v-app-bar class="white" flat app clipped-left> -->
      <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-bold"
        ><router-link
          to="/"
          >
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="70.000000pt" height="100%" viewBox="250 0 500.000000 326.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,326.000000) scale(0.100000,-0.100000)"
fill="#5c2e91" stroke="none">
<path fill="#5c2e91" d="M3290 2260 l0 -170 25 0 25 0 0 170 0 170 -25 0 -25 0 0 -170z"/>
<path fill="#5c2e91" d="M3400 2260 c0 -94 3 -169 8 -167 120 66 282 161 282 166 0 4 -42 31
-92 59 -51 28 -117 65 -145 82 l-53 30 0 -170z"/>
<path fill="#5c2e91" d="M3257 2045 c-64 -16 -107 -51 -152 -123 -1 -1 -21 16 -44 38 -113
107 -292 82 -376 -54 -76 -124 -10 -302 131 -351 32 -11 38 -17 35 -37 -1 -13
-4 -57 -5 -99 -1 -68 2 -80 29 -125 40 -63 107 -106 179 -112 l54 -4 -15 -45
c-40 -117 26 -258 142 -303 44 -17 134 -15 180 4 75 32 134 107 149 188 6 33
-3 98 -20 141 -5 14 0 17 26 17 156 0 273 162 226 313 -13 44 -13 49 2 53 129
41 191 119 192 242 0 126 -93 225 -224 239 -64 7 -137 -22 -186 -72 l-33 -34
-40 45 c-21 24 -53 52 -71 61 -42 23 -127 31 -179 18z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M4483 1892 c-103 -37 -167 -96 -214 -197 -84 -179 -47 -402 86 -523
71 -65 145 -94 245 -96 93 -1 141 15 196 66 l43 39 6 -45 7 -46 59 0 59 0 0
220 0 220 -170 0 -170 0 0 -70 0 -70 90 0 90 0 -6 -27 c-34 -146 -222 -191
-329 -79 -115 119 -89 388 44 456 48 24 138 27 179 6 35 -18 68 -54 81 -88 10
-27 12 -28 95 -28 l84 0 -10 43 c-20 88 -76 157 -168 203 -73 37 -216 45 -297
16z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M5341 1670 c-24 -11 -59 -37 -78 -58 -19 -20 -34 -32 -33 -27 2 6 2
27 1 48 l-1 37 -75 0 -75 0 0 -290 0 -290 79 0 79 0 4 168 c3 146 6 172 25
207 27 52 62 70 135 70 l59 0 -3 75 -3 75 -35 3 c-19 1 -55 -7 -79 -18z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M5690 1684 c-80 -15 -145 -60 -171 -118 -33 -72 -30 -76 61 -76 66 0
80 3 80 16 0 8 13 29 29 45 25 24 37 29 81 29 41 0 56 -5 75 -25 14 -13 25
-33 25 -45 0 -28 -33 -58 -72 -64 -18 -3 -72 -12 -120 -20 -52 -8 -103 -23
-124 -37 -103 -63 -93 -231 16 -286 75 -38 202 -30 272 19 34 24 38 23 38 -7
0 -25 1 -25 86 -25 66 0 83 3 77 13 -4 6 -10 116 -13 244 -6 265 -7 268 -93
312 -37 18 -67 24 -135 27 -48 1 -98 0 -112 -2z m180 -346 c0 -49 -18 -103
-39 -122 -27 -24 -84 -36 -127 -26 -45 10 -52 17 -60 56 -10 52 25 80 126 103
47 10 88 19 93 20 4 0 7 -14 7 -31z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M6396 1680 c-16 -5 -46 -23 -68 -40 l-38 -31 0 31 0 30 -75 0 -75 0
0 -390 0 -390 80 0 80 0 0 135 0 134 25 -25 c88 -88 271 -66 344 40 80 116 79
314 -3 421 -56 73 -182 113 -270 85z m94 -128 c63 -35 94 -141 70 -241 -23
-97 -117 -140 -198 -91 -45 28 -65 79 -65 166 1 86 26 143 75 167 43 22 76 21
118 -1z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M7003 1681 c-49 -13 -119 -57 -152 -98 -98 -119 -81 -341 32 -438 59
-50 122 -68 223 -63 106 6 163 37 212 118 17 30 32 58 32 62 0 4 -31 8 -70 8
-57 0 -71 -3 -79 -19 -23 -43 -134 -66 -184 -39 -41 22 -67 64 -67 109 0 18 9
19 206 19 l207 0 -5 73 c-7 112 -63 203 -153 249 -44 23 -148 33 -202 19z
m150 -139 c21 -18 47 -64 47 -82 0 -6 -44 -10 -125 -10 -115 0 -125 1 -125 19
0 26 38 71 74 87 38 17 103 10 129 -14z"/>
</g>
</svg></router-link
        ></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-text-field
        flat
        hide-details
        append-icon="mdi-magnify"
        placeholder="검색"
        outlined
        dense
        @click:append="search"
        class="hidden-sm-and-down"
      ></v-text-field>
      <!-- <v-toolbar-title>Page title</v-toolbar-title> -->

      <v-spacer></v-spacer>


      <v-tooltip bottom>
        <template v-slot:activator="{ on }">          
         <v-btn icon v-on="on" @click.stop="modal"> <v-icon size="25">mdi-format-paint</v-icon></v-btn>
        </template>
        <span>콘텐츠 업로드</span>
        <upload-video-modal
        :open-dialog="updialog"
        v-on:closeDialog="updialog = false"
        ></upload-video-modal>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-7" @click.stop="ndialog = true">
            <v-icon size="25">mdi-bell</v-icon></v-btn
          >
          <notification-dialog v-model="ndialog" content-class="my-custom-dialog"></notification-dialog>
        </template>
        <span>알림</span>
      </v-tooltip>

      <!-- CREATE VIDEO BUTTON -->
      <!-- <v-menu offsetY>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="mr-8 createVideoBtn"
                outlined
                v-on="{ ...tooltip, ...menu }"
                ><v-icon size="25" class="small" color ="#5c2e91"
                  >mdi-video-plus</v-icon
                >
                Create</v-btn
              >
            </template>
            <span>Create a video and more</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item @click="modal">
            <v-list-item-icon class="mr-3"
              ><v-icon>mdi-play-box-outline</v-icon></v-list-item-icon
            >
            <v-list-item-title>Upload video</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon class="mr-3"
              ><v-icon>mdi-access-point</v-icon></v-list-item-icon
            >
            <v-list-item-title>Go live</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn small color="grey lighten-3" depressed fab v-on="on" class="white--text">
            <v-avatar
            >
              <v-img :src="require('@/assets/grape_logo_only.png')" alt="alt" height="80%" weight="80%" contain/>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-btn small color="grey lighten-3" depressed fab>
                  
                  <v-avatar
                  >
                  <v-img :src="require('@/assets/grape_logo_only.png')" alt="alt" height="80%" weight="80%" contain/>
                </v-avatar>
              </v-btn>
              </v-list-item-avatar>
              
              <v-list-item-content>
                  <v-list-item-title>나무</v-list-item-title>
                  <v-list-item-subtitle
                    >namu@gmail.com</v-list-item-subtitle
                  >
                </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item router to="/channels/10">
              <v-list-item-icon>
                <v-icon>mdi-account-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>내 채널</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/studio">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>대시보드</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/signin">
              <v-list-item-icon>
                <v-icon>mdi-login-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$vuetify.theme.dark = !$vuetify.theme.dark">
              
              <!-- <v-switch
                v-model="$vuetify.theme.dark"
                hint="This toggles the global state of the Vuetify theme"
                inset
                label="Vuetify Theme Dark"
                persistent-hint
              ></v-switch> -->
              <v-list-item-icon>
                <v-icon>mdi-theme-light-dark</v-icon>
              </v-list-item-icon>
              <v-list-item-title>다크모드 전환</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>


    <v-navigation-drawer
      v-model="drawer"
      app
      :clipped="$route.name !== 'Watch'"
      :temporary="$route.name === 'Watch'"
      id="nav"
    >
      <div tag="div" class="v-navigation-drawer__content" v-bar>
        <v-list dense nav class="py-0" tag="div">
          <v-list-item
            :class="{
              'hidden-lg-and-up': $route.name === 'Watch' ? false : true
            }"
          >
            <v-app-bar-nav-icon
              @click="drawer = !drawer"
              class="mr-5"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="font-weight-bold">Grape</v-toolbar-title>
          </v-list-item>
          <v-divider class="hidden-lg-and-up"></v-divider>
          <div v-for="parentItem in items" :key="parentItem.header">
            <v-subheader
              v-if="parentItem.header"
              class="pl-3 py-4 subtitle-1 font-weight-bold text-uppercase"
              >{{ parentItem.header }}</v-subheader
            >
            <v-list-item
              v-for="(item, i) in parentItem.pages"
              :key="item.title"
              link
              class="mb-0"
              router
              :to="item.link"
              exact
              active-class="active-item"
              @click="(()=>{
                if(item.title === '콘텐츠 업로드'){updialog=true}
                else if(item.title === '의견 보내기'){feeddialog=true}
                
                })"
            >
              <v-list-item-icon v-if="parentItem.header !== 'Subscriptions'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-avatar v-else class="mr-5">
                <img
                  :src="avatars[i]"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class=" font-weight-medium subtitle-2">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2 mb-2"></v-divider>
          </div>

          <!-- links to tos+ etc. -->
          <!-- <span v-for="link in links" :key="link.text">
            <span v-if="link.text === 'Terms'" class="mb-2 d-block"> </span>
            <v-btn
              href
              router
              :to="link.link"
              text
              class="text-capitalize px-1"
              small
              >{{ link.text }}</v-btn
            >
          </span> -->
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-dialog
      v-model="feeddialog"
      scrollable transition="slide-x-reverse-transition"
      fullscreen
      
    >
      <div class="d-flex" style="height: 100%;"
      @click="feeddialog=false"
      >
        <v-spacer></v-spacer>
        <v-card width="350px" height="100%"
        
      @click.stop=""
      :ripple="false"
        >
          <v-card-title>
            <div class="d-flex justify-space-between" style="width: 100%;">
              Grape에 의견을 보내주세요
              <v-btn icon @click="feeddialog=false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            의견을 설명해주세요
            <v-textarea
            outlined
            placeholder="이 의견을 제시한 이유를 알려주세요"
            hint="민감한 정보는 포함하지 마세요"
            persistent-placeholder
            persistent-hint
            >

            </v-textarea>
            스크린샷을 주시면 Grape에서 의견을 더 잘 이해하는데 도움이 됩니다. (선택사항)
            <v-btn outlined width="100%" @click="ssdialog=true"><v-icon>mdi-monitor-screenshot</v-icon> 스크린샷 캡쳐</v-btn>
          </v-card-text>
          <div class="" style="position: absolute;bottom: 0;" >

            <v-card-text>
              <v-checkbox label="추가 정보와 최신 소식이 담긴 이메일 전송에 동의" v-model="emailAgree" value="emailAgree"></v-checkbox>
              <p class="text-caption undeco">일부 <a>계정 및 시스템 정보</a>가 Grape에 전송될 수 있습니다. 이 정보는 <a>개인정보처리방침</a> 및 <a>서비스 약관</a>에 따라 문제를 해결하고 서비스를 개선하는 데 사용됩니다. 이메일로 추가 정보와 소식을 전달해 드릴 수 있습니다. 법적인 이유로 콘텐츠 변경을 요청하려면 <a>법률 정보 고객센터</a> 로 이동하세요.</p>
            </v-card-text>
          </div>
        </v-card>
        
      </div>
    </v-dialog>
    <v-dialog
      v-model="ssdialog"
      class="mx-auto"
      width="450px"
    >
      <v-card
      >
      <v-card-title >
      </v-card-title>
        <v-card-text>
          www.grape.com에서 이 탭을 보도록 허용하시겠습니까?
          <p class="text-caption">사이트에서 이 탭의 콘텐츠를 볼 수 있습니다.</p>

          <v-img
          height="300"
          :src="require('@/assets/placeholders/screenshot_placeholder.png')"
          >

          </v-img>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex" style="width: 100%;">
            <v-spacer></v-spacer>
            <v-btn
            text
            color="primary"
            @click="ssdialog = false"
          >
            허용
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="ssdialog = false"
          >
            취소
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <upload-video-modal
        :open-dialog="updialog"
        v-on:closeDialog="updialog = false"
        ></upload-video-modal>
  </nav>
</template>

<script>
import NotificationDialog from '@/components/dialogs/NotificationDialog.vue';
import UploadVideoModal from '@/components/UploadVideoModal'

export default {
  data: () => ({
    avatars:[
      require('@/assets/avatars/avatar_01.png'),
      require('@/assets/avatars/avatar_02.jpg'),
      require('@/assets/avatars/avatar_03.png'),
      require('@/assets/avatars/avatar_04.png'),
      require('@/assets/avatars/avatar_05.png'),
      require('@/assets/avatars/avatar_06.png'),
      require('@/assets/avatars/avatar_07.png'),
    ],
    emailAgree: false,
    ssdialog:false,
    feeddialog: false,
    ndialog: false,
    updialog: false,
    drawer: false,
    items: [
      {
        header: null,
        pages: [
          { title: '홈', link: '/', icon: 'mdi-home' },
          {
            title: '구독',
            link: 'subscriptions',
            icon: 'mdi-youtube-subscription'
          },
        ]
      },
      {
        header: null,
        pages: [
          { title: '나', link: 'mychannel/main', icon: 'mdi-image-multiple' },
          {
            title: '감상 기록',
            link: 'history',
            icon: 'mdi-clock-time-four-outline'
          },
        ]
      },
      {
        header: null,
        pages: [
          {
            title: '로그인',
            link: 'signin',
            icon: 'mdi-login-variant'
          }
        ]
      },
      {
        header : null,
        pages : [
          { title: '인기 급상승', link: '/trending', icon: 'mdi-fire' },
          { title: '콘텐츠 업로드', link: '', icon: 'mdi-upload-box' },
        ]
      },
    
      {
        header : null,
        pages : [
          { title: '설정', link: '/config', icon: 'mdi-cog' },
          { title: '신고기록', link: '/reports', icon: 'mdi-flag' },
          { title: '의견 보내기', link: '', icon: 'mdi-message-alert' },
        ]
      },
    ],
    searchText: ''
  }),
  methods: {
    search() {
      if (!this.searchText) return
      this.$router.push({
        name: 'Search',
        query: { 'search-query': this.searchText }
      })
    },
    
    modal() {
      this.updialog = true
    },
  },
  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true
    this.drawer = this.$route.name === 'Watch' ? false : this.drawer
  },
  components:{
    NotificationDialog,
    UploadVideoModal,
  },
  computed:{
    // text color of current theme

  }
}
</script>

<style lang="scss">
#navbar {
  .active-item {
    .v-list-item__icon {
      // red -> 5c2e91
      color: #5c2e91 !important;
    }
  }
  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
    background: #dfe9fe;
  }

  .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
    width: 10px;
    background-color: #e0e0e0;
  }

  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
}


.undeco a{
    text-decoration: none;
    color: #065fd4 !important;
  }
</style>