import { render, staticRenderFns } from "./MyChannelNavBar.vue?vue&type=template&id=29190528"
import script from "./MyChannelNavBar.vue?vue&type=script&lang=js"
export * from "./MyChannelNavBar.vue?vue&type=script&lang=js"
import style0 from "./MyChannelNavBar.vue?vue&type=style&index=0&id=29190528&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports